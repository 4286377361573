<template>
<pageContent :hero_img="page_img" :title="page_title" :content="page_content" cta>
    <projects project_block_title="Past Projects" post_limit="3" />
</pageContent>
</template>
<script>
import pageContent from '@/components/content.vue'
import projects from '@/components/projects.vue'
export default {
    components:{
        pageContent,
        projects
    },
    data(){
        return{
            page_img: "",
            page_title: null,
            page_content: null
        }
    },
    mounted(){
        axios
    .get('https://wordpress-310083-949429.cloudwaysapps.com/wp-json/wp/v2/pages?slug=contact&_embed')
    .then( response => (
      (this.page_img = response.data[0]._embedded['wp:featuredmedia'][0].source_url),
      (this.page_title = response.data[0].title.rendered),
      (this.page_content = response.data[0].content.rendered)
    ))
  }
}
</script>
